<template>
  <div class="indexPage" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <div class="indSection1 relative wow">
      <video v-if="hasvideo" muted autoplay loop :src="bannerInfo.videoUrl"></video>
      <div class="cont">
        <div class="tit" v-if="curLang == 'zh_CN'">
          <div class="font font1">
            <span class="item" v-for="item of 4" :key="'aniSpan' + item">
              <span class="iImg">
                <img :src="require('@/assets/images/slogan'+ item + '.png')" alt="">
                <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
              </span>
              <span class="iImg">
                <img :src="require('@/assets/images/slogan'+ item + '.png')" alt="">
                <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
              </span>
            </span>
          </div>
          <div class="font font2">
            <span class="item" v-for="item of 4" :key="'aniSpan' + item">
              <span class="iImg">
                <img :src="require('@/assets/images/slogan'+ (item+4) + '.png')" alt="">
                <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
              </span>
              <span class="iImg">
                <img :src="require('@/assets/images/slogan'+ (item+4) + '.png')" alt="">
                <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
              </span>
            </span>
          </div>
        </div>
        <div class="banner-en" v-else>
          <div class="intro">
            <img :src="bannerInfo.image" alt="" class="otherLanTit">
            <!-- <div class="fnt_70" v-html="bannerInfo.title.replace(/,/g,'</br>')"></div> -->
          </div>
        </div>
        <div class="subtit wow fadeInUp" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_20'" data-wow-delay="1s"> <h2>{{ bannerInfo.intro }}</h2></div>
        <div class="desc  wow fadeIn" :class="curLang == 'zh_CN' ? 'fnt_20' : 'fnt_18 foreignDiv'" data-wow-delay="1.1s" v-html="bannerInfo.introTwo"></div>
      </div>
    </div>

    <div class="indSection1 relative wow">
      <div class="bg wow fadeIn"></div>
      <div class="absolute pCenter"><div class="circle4 wow zoomIn"></div></div>
      <div class="absolute pCenter"><div class="circle3 wow zoomIn"></div></div>
      <div class="absolute pCenter"><div class="circle2 wow zoomIn"></div></div>
      <div class="absolute pCenter"><div class="wow zoomIn"><div class="circle1"></div></div></div>
      <div class="intro absolute pCenter" :class="curLang == 'zh_CN' ? '' : 'foreignDiv'">
        <div class="tit fnt_32 wow fadeIn" data-wow-delay="1s">  {{ intro.title }}  </div>
        <div class="subtit wow fadeInUp" :class="curLang == 'zh_CN' ? 'fnt_70' : 'fnt_42'" data-wow-delay="1s" v-html="intro.subtitle.replace(/\n/g,'</br>')"></div>
        <div class="desc fnt_20 wow fadeIn" data-wow-delay="2s" v-html="intro.content"></div>
      </div>
    </div>

    <div class="indSection2 relative">
      <div class="mask pCenter absolute"></div>
      <div class="cont pCenter absolute">
        <div class="tit  wow fadeIn" :class="curLang == 'zh_CN' ? 'fnt_42' : 'fnt_32'">{{ globalDataArray.siteTemplateLanguage.index_dasai_money }}</div>
        <div class="num">
          <span class="item wow fadeInRight" data-wow-delay="0.2s">
            <img src="@/assets/images/ind2.png" alt="">
            <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
          </span>
          <span class="item wow fadeInRight" data-wow-delay="0.3s">
            <img src="@/assets/images/ind3.png" alt="">
            <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
          </span>
          <span class="item wow fadeInRight" data-wow-delay="0.4s">
            <img src="@/assets/images/ind4.png" alt="">
            <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
          </span>
          <span class="item wow fadeInRight" data-wow-delay="0.5s">
            <img src="@/assets/images/ind5.png" alt="">
            <span class="aniSpan absolute pFull"><span class="aniSpan1"></span><span class="aniSpan2"></span></span>
          </span>
        </div>
        <div class="subtit wow fadeIn" :class="curLang == 'zh_CN' ? 'fnt_42' : 'fnt_32'" data-wow-delay="1s">{{ globalDataArray.siteTemplateLanguage.index_dasai_money_desc }}</div>
      </div>

      <div class="itemBox">
        <div class="row">
          <div class="item wow fadeInRight" data-wow-delay="0.2s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.4s">&nbsp;</div>
          <div class="dot wow fadeInRight" data-wow-delay="0.5s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.6s">&nbsp;</div>
        </div>

        <div class="row">
          <div class="font wow fadeInLeft" data-wow-delay="0.2s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[0].title }}</div>
            <div class="fnt_16 f2">{{ priceList[0].subtitle }}</div>
          </div>
          <div class="item wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
          <div class="font wow fadeInLeft" data-wow-delay="0.4s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[4].title }}</div>
            <div class="fnt_16 f2">{{ priceList[4].subtitle }}</div>
          </div>
          <div class="item wow fadeInLeft" data-wow-delay="0.5s">&nbsp;</div>
        </div>
        <div class="row">
          <div class="font wow fadeInRight" data-wow-delay="0.2s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[1].title }}</div>
            <div class="fnt_16 f2">{{ priceList[1].subtitle }}</div>
          </div>
          <div class="item wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.4s">&nbsp;</div>
          <div class="dot wow fadeInRight" data-wow-delay="0.5s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.6s">&nbsp;</div>
        </div>

        <div class="row">
          <div class="font wow fadeInLeft" data-wow-delay="0.2s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[2].title }}</div>
            <div class="fnt_16 f2">{{ priceList[2].subtitle }}</div>
          </div>
          <div class="item wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
          <div class="font wow fadeInLeft" data-wow-delay="0.4s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[5].title }}</div>
            <div class="fnt_16 f2">{{ priceList[5].subtitle }}</div>
          </div>
        </div>

        <div class="row">
          <div class="item wow fadeInRight" data-wow-delay="0.2s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.3s">&nbsp;</div>
          <div class="item wow fadeInRight" data-wow-delay="0.4s">&nbsp;</div>
          <div class="dot wow fadeInRight" data-wow-delay="0.5s">&nbsp;</div>
          <div class="font wow fadeInRight" data-wow-delay="0.6s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[6].title }}</div>
            <div class="fnt_16 f2">{{ priceList[6].subtitle }}</div>
          </div>
        </div>

        <div class="row">
          <div class="font wow fadeInLeft" data-wow-delay="0.2s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[3].title }}</div>
            <div class="fnt_16 f2">{{ priceList[3].subtitle }}</div>
          </div>
          <div class="item wow fadeInLeft" data-wow-delay="0.3s">&nbsp;</div>
          <div class="font wow fadeInLeft" data-wow-delay="0.4s">
            <div class="f1" :class="curLang == 'zh_CN' ? 'fnt_32' : 'fnt_22'">{{ priceList[7].title }}</div>
            <div class="fnt_16 f2">{{ priceList[7].subtitle }}</div>
          </div>
          <div class="item wow fadeInLeft" data-wow-delay="0.5s">&nbsp;</div>
        </div>

        <div class="row">
          <div class="item wow fadeInRight" data-wow-delay="0.2s"></div>
          <div class="item wow fadeInRight" data-wow-delay="0.3s"></div>
          <div class="item wow fadeInRight" data-wow-delay="0.4s"></div>
          <div class="item wow fadeInRight" data-wow-delay="0.5s"></div>
          <div class="dot wow fadeInRight" data-wow-delay="0.6s"></div>
        </div>
      </div>
    </div>
    <div class="relative pt-10vh w-1580px mx-auto flex justify-between items-start flex-wrap wow fadeIn <2xl:(w-full)" data-wow-delay="0.5s">
      <div class="w-280px flex flex-col shrink-0 justify-center wow fadeInUpBig <2xl:(w-25%) <xl:(w-50% ml-0px) <sm:(w-100%)">
        <div class="h-58px flex items-end justify-center">
          <img :src="require('@/assets/images/screen-1.svg')" :alt="'screen-1'" class="w-231px h-full">
          <span class="font-600 text-30px leading-30px text-#002762 ml-9px">
            {{ globalDataArray.siteTemplateLanguage.home_static_top_1 }}
          </span>
        </div>
        <span class="mt-39px font-400 text-20px text-#002762 text-center break-anywhere">
          {{ globalDataArray.siteTemplateLanguage.home_static_bottom_1 }}
        </span>
      </div>
      <div class="w-280px ml-153px flex flex-col shrink-0 justify-center wow fadeInUpBig <2xl:(w-25% ml-0px) <xl:(w-50% ml-0px) <sm:(w-100% mt-80px)">
        <div class="h-56px flex items-end justify-center">
          <img :src="require('@/assets/images/screen-2.svg')" :alt="'screen-2'" class="w-124px h-full">
          <span class="font-600 text-30px leading-30px text-#002762 ml-9px">
            {{ globalDataArray.siteTemplateLanguage.home_static_top_2 }}
          </span>
        </div>
        <span class="mt-39px font-400 text-20px text-#002762 text-center break-anywhere">
          {{ globalDataArray.siteTemplateLanguage.home_static_bottom_2 }}
        </span>
      </div>
      <div class="w-280px ml-153px flex flex-col justify-center shrink-0 wow fadeInUpBig <2xl:(w-25% ml-0px) <xl:(w-50% ml-0px mt-80px) <sm:(w-100% mt-80px)">
        <div class="h-58px flex items-end justify-center">
          <img :src="require('@/assets/images/screen-3.svg')" :alt="'screen-3'" class="w-184px h-full">
          <span class="font-600 text-30px leading-30px text-#002762 ml-9px">
            {{ globalDataArray.siteTemplateLanguage.home_static_top_3 }}
          </span>
        </div>
        <span class="mt-39px font-400 text-20px text-#002762 text-center break-anywhere">
          {{ globalDataArray.siteTemplateLanguage.home_static_bottom_3 }}
        </span>
      </div>
      <div class="w-280px ml-153px flex flex-col justify-center shrink-0 wow fadeInUpBig <2xl:(w-25% ml-0px) <xl:(w-50% ml-0px mt-80px) <sm:(w-100% mt-80px)">
        <div class="h-58px flex items-end justify-center">
          <img :src="require('@/assets/images/screen-4.svg')" :alt="'screen-4'" class="w-130px h-full">
          <span class="font-600 text-30px leading-30px text-#002762 ml-9px">
            {{ globalDataArray.siteTemplateLanguage.home_static_top_4 }}
          </span>
        </div>
        <span class="mt-39px font-400 text-20px text-#002762 text-center break-anywhere">
          {{ globalDataArray.siteTemplateLanguage.home_static_bottom_4 }}
        </span>
      </div>
    </div>

    <!-- 时间安排 -->
    <div class="relative mt-135px wow fadeIn" data-wow-delay="0.5s">
      <div class="indTit">
        <header class="text-center f1 fnt_60 wow fadeInUpBig">
          {{ globalDataArray.siteTemplateLanguage.home_static_shedule_1 }}
        </header>
      </div>
      <div class="w-793px mt-109px mx-auto flex <md:(w-280px) mt-52px">
        <div class="w-32px h-392px mr-40px mt-16px shrink-0 bg-no-repeat progress <md:(w-12px h-156px mr-16px mt-10px) wow fadeInLeftBig"></div>
        <ul class="w-721px wow fadeInUp <md:(w-394px)">
          <li class="flex w-full h-64px <md:(h-32px)">
            <span class="w-184px h-full rounded-l-4px text-white   text-center leading-64px likebutton <md:(w-72px leading-32px)" :class="curLang == 'zh_CN' ? 'text-26px font-600 <md:text-14px' : 'text-22px font-400 <md:text-9px'">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_2 }}
            </span>
            <span class="flex-1 pl-32px h-full text-26px font-600 leading-64px text-#002762 likebutton1 <md:(pl-8px leading-32px text-12px)">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_value_1 }}
            </span>
          </li>
          <li class="flex w-full h-64px mt-56px <md:(h-32px mt-16px)">
            <span class="w-184px h-full rounded-l-4px text-white  text-center leading-64px likebutton <md:(w-72px leading-32px)" :class="curLang == 'zh_CN' ? 'text-26px font-600 <md:text-14px' : 'text-22px font-400 <md:text-9px'">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_3 }}
            </span>
            <span class="flex-1 pl-32px h-full text-26px font-600 leading-64px text-#002762 likebutton1 <md:(pl-8px leading-32px text-12px)">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_value_2 }}
            </span>
          </li>
          <li class="flex w-full h-64px mt-56px <md:(h-32px mt-16px)">
            <span class="w-184px h-full rounded-l-4px text-white  text-center leading-64px likebutton <md:(w-72px leading-32px)" :class="curLang == 'zh_CN' ? 'text-26px font-600 <md:text-14px' : 'text-22px font-400 <md:text-9px'">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_4 }}
            </span>
            <span class="flex-1 pl-32px h-full text-26px font-600 leading-64px text-#002762 likebutton1 <md:(pl-8px leading-32px text-12px)">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_value_3 }}
            </span>
          </li>
          <li class="flex w-full h-64px mt-56px <md:(h-32px mt-16px)">
            <span class="w-184px h-full rounded-l-4px text-white text-center leading-64px likebutton <md:(w-72px leading-32px)" :class="curLang == 'zh_CN' ? 'text-26px font-600 <md:text-14px' : 'text-22px font-400 <md:text-9px'">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_5 }}
            </span>
            <span class="flex-1 pl-32px h-full text-26px font-600 leading-64px text-#002762 likebutton1 <md:(pl-8px leading-32px text-12px)">
              {{ globalDataArray.siteTemplateLanguage.home_static_shedule_value_4 }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 专家组委会 -->
    <div class="indSection4 relative" v-if="expertList.length>0">
      <div class="indBg1 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg1.png" alt=""></div></div>
      <div class="indBg2 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg2.png" alt=""></div></div>
      <div class="indTit wow fadeInUp relative">
        <div class="f1 fnt_60"> {{ globalDataArray.siteTemplateLanguage.expert_list_tit }} </div>
        <div class="f2 fnt_28">{{ globalDataArray.siteTemplateLanguage.partners_desc }}</div>
      </div>
      <div class="w1580 ovh relative">
        <div class="list cf">
          <div class="item wow fadeInUp" v-for="(item,index) of expertList" :key="'expert' + index">
            <div class="w">
              <!-- <router-link to="/"> -->
              <div class="img">
                <img :src="item.image" alt="">
              </div>
              <div class="font">
                <div class="f1 fnt_30">{{ item.title }}</div>
                <div class="f2 fnt_18" v-html="item.subtitle.replace(/;/g,'<br/>')"></div>
              </div>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 赛题申报通道 -->
    <div class="indSection5 relative" v-if="saitiWay!=''&&saitiWay!=null">
      <div class="indBg3 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg3.png" alt=""></div></div>
      <div class="indBg4 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg4.png" alt=""></div></div>
      <div class="indBg5 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg5.png" alt=""></div></div>
      <div class="cont absolute pCenter">
        <div class="indTit">
          <div class="f1 fnt_60 wow fadeInUp" data-wow-delay="0.2s">  {{ saitiWay.title }}  </div>
          <div class="f2 fnt_20 wow fadeInUp" data-wow-delay="0.4s" v-html="saitiWay.content"></div>
        </div>

        <div class="more fnt_20 animated">
          <router-link v-if="saitiWay.linkUrl!=''&&saitiWay.linkUrl!=null" :to="saitiWay.linkUrl">{{ globalDataArray.siteTemplateLanguage.index_see_more }}</router-link>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="indSection6 relative">
      <div class="indBg6 absolute"><div class="wow fadeInUp"><img src="@/assets/images/bg6.png" alt=""></div></div>
      <div class="wow fadeInUp" data-wow-delay="0.2s">
        <unitswiper />
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { getIndexInfo, getAdList, getArticle } from '@/api/api'
import { getCategoryList } from '@/api/saiti'
export default {
  name: 'Home',
  data() {
    return {
      prevSlideInd: 0,
      nextSlideInd: 0,
      maxSlideInd: 0,
      swiperOption: {
        autoplay: 15000,
        loop: true,
        pagination: '.sPage1',
        paginationClickable: true,
        nextButton: '.sbn',
        prevButton: '.sbp',
        onSlideChangeEnd: swiper => {
          const curInd = swiper.activeIndex - 1
          const maxInd = this.maxSlideInd
          if (typeof document === 'object') {
            if (curInd == maxInd) {
              this.nextSlideInd = 0
              this.$nextTick(function() {
                document.getElementById('sPage1').childNodes[maxInd].classList.add('swiper-pagination-bullet-active')
              })
            } else if (curInd > maxInd) {
              this.prevSlideInd = this.maxSlideInd
              this.nextSlideInd = 1
              this.$nextTick(function() {
                document.getElementById('sPage1').childNodes[0].classList.add('swiper-pagination-bullet-active')
              })
            } else if (curInd < 0) {
              this.prevSlideInd = this.maxSlideInd - 1
              this.nextSlideInd = 0
              this.$nextTick(function() {
                document.getElementById('sPage1').childNodes[maxInd].classList.add('swiper-pagination-bullet-active')
              })
            } else if (curInd == 0) {
              this.prevSlideInd = this.maxSlideInd
              this.$nextTick(function() {
                document.getElementById('sPage1').childNodes[0].classList.add('swiper-pagination-bullet-active')
              })
            } else {
              this.prevSlideInd = curInd - 1
              this.nextSlideInd = curInd + 1
              this.$nextTick(function() {
                document.getElementById('sPage1').childNodes[curInd].classList.add('swiper-pagination-bullet-active')
              })
            }
          }
        }
      },

      curLang: '',
      bannerInfo: {
        bannerTitle: '',
        bannerIntro: '',
        introTwo: '', // 首屏文字
        videoUrl: '',
        sourceVideoUrl: ''
      },
      intro: {
        title: '',
        content: '',
        subtitle: ''
      },
      priceList: [
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' },
        { title: '', subtitle: '' }
      ],
      expertList: [],

      category: [
        {
          id: ' ',
          image: ' ',
          imageLg: null,
          parentId: ' ',
          subTitle: ' ',
          title: ''
        },
        {
          id: ' ',
          image: ' ',
          imageLg: null,
          parentId: ' ',
          subTitle: ' ',
          title: ''
        }
      ],

      hasvideo: true,
      saitiWay: ''
    }
  },
  created() {

  },
  mounted() {
    if (typeof window !== 'undefined') {
      new WOW({
        live: false,
        offset: 0
      }).init()

      if (window.innerWidth <= 1024) {
        this.hasvideo = false
      }
    }
    this.init()

    const that = this
    const currentLanguge = Cookies.get('DEFAULT_LANG')
    if (currentLanguge != null && currentLanguge != undefined && currentLanguge != '') {
      that.curLang = currentLanguge
    } else {
      that.curLang = that.globalDataArray.siteLanguage[0].value
    }
  },
  methods: {
    init() {
      const that = this

      getIndexInfo().then(res => {
        if (res.success) {
          const result = res.result
          that.expertList = result.expertList
          that.priceList = result.priceList
          that.intro = result.intro
        }
      })
      const datas = 'alias=index_banner'
      getAdList(datas).then(res => {
        if (res.success) {
          that.bannerInfo = res.result[0]
          // that.curLang = res.result[0].language
        }
      })
      getCategoryList().then(res => {
        if (res.success) {
          that.category = res.result
          // console.log(that.category)
          that.maxSlideInd = res.result.length - 1
          that.prevSlideInd = that.maxSlideInd
          if (res.result.length > 1) {
            that.nextSlideInd = 1
          }

          setTimeout(() => {
            document.getElementById('sPage1').childNodes[0].classList.add('swiper-pagination-bullet-active')
          }, 500)
        }
      })

      getArticle('type=7').then(res => {
        if (res.success) {
          if (res.result.length > 0) {
            that.saitiWay = res.result[0]
          }
        }
      })
    }

  },
  metaInfo() {
    const title = this.globalDataArray.siteInfo.title
    let description = ''
    let keywords = ''

    if (this.globalDataArray.siteNavigation[0].description != '' && this.globalDataArray.siteNavigation[0].description != null) {
      description = this.globalDataArray.siteNavigation[0].description
    } else {
      description = this.globalDataArray.siteInfo.description
    }
    if (this.globalDataArray.siteNavigation[0].keywords != '' && this.globalDataArray.siteNavigation[0].keywords != null) {
      keywords = this.globalDataArray.siteNavigation[0].keywords
    } else {
      keywords = this.globalDataArray.siteInfo.keywords
    }
    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>

<style lang="scss">
.likebutton {
  background: linear-gradient( 270deg, #EC9B39 0%, #F63F3E 46%, #3458CF 100%);
}

.likebutton1 {
  background: linear-gradient( 270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}

.progress {
  background-image: url('@/assets/images/progress.png');
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  .progress {
    background-image: url('@/assets/images/progress-wap.png');
  }
}
</style>
